import * as Yup from "yup";
import { YupDefaultProps, YupSelectProps, YupStringProps } from "./types";
import { string, array, boolean } from "yup";
import { Option } from "../types";

const fieldRequiredMessage = "Este campo es obligatorio.";
const emailFormatMessage = "El formato debe ser direccion@mail.com";
const specialCharactersMessage = "No debe contener caracteres especiales.";
const lettersMessage = "No debe contener números ni caracteres especiales.";
const maxMessage = (max: number) => `Debe tener menos de ${max} caracteres.`;
const tokenMessage = "Debe tener 7 caracteres.";

export const yupString = ({
  min,
  max,
  required,
  minMessage,
  maxMessage,
  requiredMessage,
  regex,
  regexMessage,
}: YupStringProps) => {
  let schema = Yup.string();
  if (min) schema = schema.min(min, minMessage);
  if (max) schema = schema.max(max, maxMessage);
  if (regex) schema = schema.matches(regex, regexMessage);
  if (required) schema = schema.required(requiredMessage);
  return schema;
};

export const yupSelect = ({
  type,
  options,
  optionsMessage,
  required,
  requiredMessage,
}: YupSelectProps) => {
  let schema;

  const optionsSchema = Yup.string().oneOf(
    options.map((option) => option.value),
    optionsMessage
  );

  if (type === "single") {
    schema = optionsSchema;
  }

  if (type === "multiple") {
    schema = Yup.array().of(optionsSchema);
  }

  if (required && schema) {
    schema = schema.required(requiredMessage);
  }

  return schema;
};

export const YupNumber = ({
  min,
  max,
  required,
  minMessage,
  maxMessage,
  requiredMessage,
}: YupDefaultProps) => {
  let schema = Yup.number();

  if (min) schema = schema.min(min, minMessage);
  if (max) schema = schema.max(max, maxMessage);
  if (required) schema = schema.required(requiredMessage);
  return schema;
};

export const stringRequired = string().required(fieldRequiredMessage);

export const numberRequired = string()
  .matches(/^[0-9]*$/, "Deben ser solo números.")
  .required(fieldRequiredMessage);

export const smallStringRequired = string()
  .max(50, maxMessage(50))
  .required(fieldRequiredMessage);

export const smallString = string().max(50, maxMessage(50)).nullable();

export const mediumStringRequired = string()
  .max(100, maxMessage(100))
  .required(fieldRequiredMessage);

export const largeStringRequired = string()
  .max(200, maxMessage(200))
  .required(fieldRequiredMessage);

export const noSpecialCharactersRequired = string()
  .matches(/^[a-zA-Z0-9]*$/, specialCharactersMessage)
  .required(fieldRequiredMessage);

export const lettersRequired = string()
  .matches(/^[a-zA-Z]*$/, lettersMessage)
  .required(fieldRequiredMessage);

export const checkboxRequired = boolean().oneOf(
  [true],
  "Este campo es obligatorio"
);
export const checkbox = boolean().notRequired();

// Personal information
export const emailRequired = string()
  .email(emailFormatMessage)
  .required(fieldRequiredMessage);

export const phone = string()
  .matches(/^\d{8}$|^\d{12}$/, "Deben ser 8 o 12 números.")
  .required(fieldRequiredMessage);

export const dniRequired = string()
  .matches(/^[0-9]{7,8}$/, "Deben ser 7 u 8 números.")
  .required(fieldRequiredMessage);

export const tramitNumberRequired = string()
  .matches(/^[0-9]{11}$/, "Deben ser 11 números.")
  .required(fieldRequiredMessage);

export const cuitRequired = string()
  .matches(/^(20|23|24|27|30|33)/, "Debe comenzar con 20, 23, 24, 27, 30 o 33.")
  .matches(/^[0-9]{11}$/, "Deben ser 11 números.")
  .required(fieldRequiredMessage);

export const cbuNotRequired = string()
  .matches(/^[0-9]{22}$/, "Deben ser 22 números.")
  .nullable();
export const cbuRequired = cbuNotRequired.required(fieldRequiredMessage);

export const radioOrSingleSelectRequired = (options: Option[]) =>
  string()
    .oneOf(options.map((option) => option.value))
    .required(fieldRequiredMessage);

export const tokenRequired = noSpecialCharactersRequired
  .min(7, tokenMessage)
  .max(7, tokenMessage);

export const zipCodeRequired = string()
  .matches(/^[a-zA-Z0-9\s-]+$/, specialCharactersMessage)
  .max(50, maxMessage(50))
  .required(fieldRequiredMessage);

export const multipleSelectRequired = (options: Option[]) => {
  const optionsSchema = Yup.string().oneOf(
    options.map((option) => option.value),
    "Debe seleccionar al menos una opción."
  );

  return array().of(optionsSchema).required(fieldRequiredMessage);
};

export const fileRequired = (supportedFormats: string[], maxFileSize: number) =>
  Yup.mixed()
    .required(fieldRequiredMessage)
    .test("fileFormat", "FORMAT-ERROR", (value) => {
      const file = value as File;

      if (!value || file?.type) return false;

      return supportedFormats.includes(file.type);
    })
    .test("fileSize", "SIZE-ERROR", (value) => {
      const file = value as File;

      if (!value || file?.size) return false;

      file.size < maxFileSize || typeof value === "number";
    });

// Address is an object of type InputAddressProps.onValueChange data
// const addressShape = {
//   formattedAddress: Yup.object().shape({
//     street: string(),
//     streetNumber: string(),
//     city: string(),
//     state: string(),
//     country: string(),
//     postalCode: string(),
//   }),
//   fullData: Yup.object().shape({
//     prediction: Yup.object(),
//     place: Yup.object(),
//   }),
// };

// export const addressRequired = Yup.object()
//   .shape(addressShape)
//   .required(fieldRequiredMessage);
